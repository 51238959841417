.box-with-shadow {
  background-color: #FFFFFF;
  box-shadow: 0 4px 12px 0 $gray-border-select;
  padding: 3em 4em;
}

@media (min-width: 768px) {
	.box-with-shadow {
	  padding: 6em 9.5em;
	}
}