// Coachee sections
.coachee {
  // Reset
  ul {
    margin: 0 -12px;
    padding: 0;
  }
  li {
    list-style: none;
  }

  &__download-instructions {
    p {
      font-size: 16px;
      line-height: 1;
      margin-bottom: 8px;
      text-align: right;
    }

    a {
      font-size: 16px;
      line-height: 1;
      text-align: right;
    }
  }

  &__box {
    width: 100%;
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.05);
    &-container {
      padding: 12px;
    }
    &-content {
      padding: 24px;
      .coachee__image {
        width: 64px;
        height: 64px;
        border-radius: 64px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      p {
        color: $dark-gray;
        font-size: 18px;
        font-weight: bold;
        margin-top: 16px;
      }
    }
    &-calendar {
      position: absolute;
      top: 2em;
      right: 2em;
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 16px 24px;
      p {
        margin-bottom: 0;
        font-size: 14px;
      }

    }
  }
}

.coachee_item{ cursor: pointer; }