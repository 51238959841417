.live {
	width: 800px;
	background-image: url(../img/oval-tcito-live.png);
    background-position: center top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    margin-left: 245px !important;
    padding-top: 3em !important;
    padding-left: 3em !important;
    z-index: 99;
}

.live-background {
	width: 100%;
	height: 242px;
	position: absolute;
	background-color: #5e44f4;
	z-index: -1;
	top: 0;
	left: 0;
}

.modal-pago {
	background: transparent !important;

	.logo-circle{ 
		max-width: 80px; 
		margin-top: -2.2em;
		margin-bottom: 1em;
	}

	.close-btn{
		background: transparent;
		border:0;
		color: white;
		position: absolute;
		top: 1em;
		right: 1em;
		z-index: 99;
	}

	.ant-modal-body,
	.ant-modal-content{ 
		background: transparent !important; 
		padding: 0;
	}

	.modal-pago-header {
		background-color: $purple;
		border-top-left-radius: 9px;
		border-top-right-radius: 9px;
	}

	.modal-pago-body {
		padding: 2em 4em;
		border-bottom-left-radius: 9px;
		border-bottom-right-radius: 9px;
	}
}

.card-item{
	background-color: white;
	border: 1px solid #efefef;
	border-radius: 7px;
	padding: 1em 0;
	color: $edit-gray;

	&.active{
		border: 1px solid $purple;
		color: $purple;
	}
}

@media (min-width: 1024px) {
	.live {
		width: 750px !important;
	    margin-left: 275px !important;
	    padding-top: 2em !important;
    	padding-left: 3em !important;
	}

	.live-background {
		height: 242px;
	}
}

@media (min-width: 1140px) {
	.live {
		width: 900px !important;
	    margin-left: 275px !important;
	    padding-top: 6em !important;
    	padding-left: 3em !important;
	}

	.live-background {
		height: 291px;
	}
}

@media (min-width: 1280px) {
	.live {
		width: 1024px !important;
	    margin-left: 275px !important;
	    padding-top: 9em !important;
    	padding-left: 6em !important;
	}

	.live-background {
		height: 331px;
	}
}