/* INPUTS */
input {
  border: 1px solid $light-gray;
  font-size: 1em;
  color: $gray;
  height: 3.6em;
  padding: 2.2em 2em;
  outline: none;
  position: relative;
  transition: border-color 0.3s linear;
}

.input-text-wrapper {

  &:last-of-type {
    margin-bottom: 0 !important;
  }
}

.input-number-wrapper {

  .btn-plus,
  .btn-minus {
    width: 30%;
    float: left;
    height: 2.05em;
  }

  .btn-plus {
    border-left: 0;
  }

  .btn-minus {
    border-right: 0;
  }

  input {
    width: 40%;
    float: left;
    padding: 0 1em;
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

input+label,
input+p+label,
textarea+label,
textarea+p+label {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  max-height: 0;
  display: none;
}

input:focus,
textarea:focus {
  border: 2px solid $purple;

  &::placeholder {
    color: transparent;
  }
}

input:focus+label,
input:focus+p+label,
textarea:focus+label,
textarea:focus+p+label {
  font-size: 1em;
  background-color: white;
  padding: 0 0.8em;
  color: $purple;
  position: absolute;
  left: 1em;
  top: -0.8em;
  visibility: visible;
  opacity: 1;
  margin-bottom: 0.8em;
  max-height: 3em;
  display: block;
}

::-webkit-input-placeholder {
  color: $gray-placeholder;
}

/* Edge */
:-ms-input-placeholder {
  color: $gray-placeholder;
}

/* Internet Explorer */
::placeholder {
  color: $gray-placeholder;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.show-password{
  position: absolute;
  right: 1.2em;
  top: 1.2em;
}

.hide-password{
  position: absolute;
  right: 1.45em;
  top: 1.4em;
}

textarea{
  border: 1px solid $light-gray;
  font-size: 1em;
  color: $gray;
  padding: 2.2em 2em;
  outline: none;
  position: relative;
  transition: border-color 0.3s linear;
}