// Menu item 
.menu-container {
  @media screen and (max-width: 1024px) {

    &:after {
      content: "";
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all .1s ease-in;
      // transition-delay: .3s;
    }

    &.active {
      &:after {
        content: "";
        opacity: 1;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 248px;
        right: 0;
        bottom: 0;
        background-color: rgba($dark-gray, .1);
      }
    }

  }

  .menu-content {
    background-color: $dark-gray;
    padding: 32px 30px 32px 15px;
    display: flex;
    flex-direction: column;
    width: 280px;
    min-height: 100vh;
    position: fixed;
    z-index: 999;

    @media screen and (max-width: 1024px) {
      position: fixed;
      top: 0;
      left: 0;
      max-width: 248px;
      padding: 24px 24px 20px;
      transition: all .1s ease;
      z-index: 1000;

      &.active {
        left: 0;
      }
    }

    img.logo {
      position: relative;
      top: 0;
      left: 15px;
      width: 100%;
      max-width: 166px;

      @media screen and (max-width: 1024px) {
        max-width: 142px;
      }
    }

    .logo-text {
      color: $dark-gray;
      font-family: Lato;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: (20/14);
      margin-top: 8px;

      @media screen and (max-width: 1024px) {
        font-size: 10.5px;
      }
    }

    .menu-greetings {
      max-width: 220px;
      margin-bottom: 40px;

      p {
        color: $gray;
        font-size: 16px;
        line-height: (25/16);

        &.name {
          color: $dark-gray;
          font-weight: bold;
        }
      }
    }

    .menu-items {
      ul {
        padding: 0;

        li {
          list-style: none;
          padding: 0;
          margin: 32px 0;

          a {
            color: $edit-gray;
            text-decoration: none;
            font-size: 14px;
            line-height: 24px;
            padding-left: 40px
          }

          a:hover{ color: $white; }

          cursor: pointer;

          &.active {
            border-left: 4px solid $purple-light;
            @media screen and (max-width: 1024px) {
              border-left: none;
            }

            a {
              color: $white;
            }
          }

          p {
            cursor: pointer;

            a {
              color: $edit-gray;
              text-decoration: none;
            }

          }

          svg {
            margin-top: -4px;
            margin-right: 18px;
          }
        }
      }

      p {
        cursor: pointer;

        a {
          color: $edit-gray;
          text-decoration: none;

          &:hover {
            color: $white !important;
          }
        }

      }
    }

    .menu-footer {
      margin-top: auto;

      p {
        color: $gray;
        font-size: 16px;
        line-height: (25/16);
        margin-bottom: 0;

        &.name {
          color: $dark-gray;
          font-weight: bold;
        }
      }

      .info-box{
        border-radius: 9px;
        background-color: #2F2F2F;
        box-shadow: 0 2px 6px 0 rgba(0,0,0,0.27);

        p{ font-size: 12px; }
      }
    }
  }

  .menu-mobile {
    display: none;

    /*@media screen and (max-width: 1024px) {
      height: 72px;
      padding: 0 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .menu-icon {
        position: absolute;
        left: 24px;
        top: 50%;
        transform: translateY(-50%);
      }
    }*/
  }

}

.submenu{
  background: $white;
  padding: 3em 1.7em;
  box-shadow: -1px 3px 6px 2px rgba(0,0,0,0.1);
  min-height: 100vh;
}