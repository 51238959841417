.orange-badge {
  background-color: $orange-light;
  color: $orange;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  display: inline-block;
  margin-left: 1em;
  display: inline-block;
  text-decoration: none;

  &:hover {
    color: $orange;
    text-decoration: none;
  }
}

.white-badge{
  background-color: white;
  border: 1px solid $purple-light;
  border-radius: 3px;
  color: $purple-light;
  padding: 4px 8px;
  text-align: center;
}

.purple-light-badge{
  background-color: $purple-light;
  border: 1px solid $purple-light;
  border-radius: 3px;
  color: $white;
  padding: 4px 8px;
  text-align: center;
}

.gray-badge{
  background-color: rgba(155,155,155,0.26);
  border-radius: 3px;
  color: $purple;
  padding: 4px 8px;
  text-align: center;
}

.purple-badge-point{
  background-color: $purple-light;
  border-radius: 50px;
  width: 8px !important;
  height: 8px !important;
  padding: 0;
  margin: 0;
  display: inline-block;
}