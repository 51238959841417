// Tarifa sections
.tarifa-card{
    border-radius: 3px;
    border-top: 2px solid $purple;
    background-color: $white;
    box-shadow: 0 7px 9px -4px rgba(140,140,140,0.14);
    padding: 2em;

    .description{
      height: 18em;
      overflow: hidden;
      padding-bottom: 1em;

      p{ margin: 0; }
    }
}