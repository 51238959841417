.toolbox {
  &-tool {
    background-color: #fff;
    box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.02);
    padding: 24px;
    display: flex;
    margin: 16px 0;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:active{
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
    &.disabled{
      cursor: default !important;
    }
    img {
      width: 48px;
      height: 48px;
      margin-right: 16px;
    }
    p {
      margin-bottom: 0;
    }

    .overlay{
      top: 0;
      left: 0;
      z-index: 999;
      background-color: rgba(90,90,90,0.95);
      opacity: 0;
      cursor: pointer;

      &:hover{ opacity: 1; }

      .icon{
        display: inline-block;
      }
    }

    &:hover .overlay{ opacity: 1; }
  }
  &.disabled{
    cursor: default !important;
  }
}

.coach-tools{
  height: calc(100% - 4em);
}