$white: #ffffff;
$white-gray: #fcfcfc;
$black: #000000;
$black-text: #4A4A4A;
$red: #ff0000;
$orange: #FF866B;
$orange-light: rgba(255, 134, 107, 0.1);
$purple: #5E44F4;
$purple-light: #DD9DEF;
$gray: #7C7977;
$gray07: rgba(124, 121, 119, 0.7);
$dark-gray: #383838;
$light-gray: #EFEFEF;
$price-gray: rgba(64, 64, 64, 0.4);
$edit-gray: #979797;
$gray-border-select: #F5F3EF;
$gray-border-checkbox: #B6B6B6;
$gray-placeholder: #B7B7B7;
$gray-bg: #f9f9f9;
$gray-bg-btn: #f4f4f4;
$gray-minimal: rgba(151, 151, 151, 0.06);
$gray-separator: rgba(176,176,176,0.86);

.opacity-0 {
  opacity:0!important;
}
.opacity-1 {
  opacity:0.2!important;
}
.opacity-2 {
  opacity:0.4!important;
}
.opacity-3 {
  opacity:0.6!important;
}
.opacity-4 {
  opacity:.8!important;
}
.opacity-5 {
  opacity:1!important;
}