.score {
  position: relative;
  width: 80px;
  margin: 0;

  &__bg,
  &__value {

    span {
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: #d8d8d8;
      transform: scale(.5);
    }
  }

  &__value {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--value);
    white-space: nowrap;
    overflow: hidden;

    span {
      background-color: $orange;
    }
  }
}