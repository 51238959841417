.managementPanel {
  &__content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
  }

  &__title {
    color: #404040;
    font-size: 20px;
    line-height: 1.4;
    font-weight: bold;
    letter-spacing: .67px;
  }
}