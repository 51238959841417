// Helpers
.split-container {
  display: flex;
  min-height: 100vh;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  .container {
    padding: 24px;
    margin-left: 285px;

    @media screen and (max-width: 1024px) {
      padding: 0 24px 60px;
    }
  }
}

.border-bottom-gray {
  border-bottom: 1px solid $edit-gray;
}

.border-bottom-gray-bg {
  border-bottom: 1px solid $gray-separator;
}

.border-bottom-gray-min {
  border-bottom: 1px solid $gray-bg-btn;
}

.border-radius-100 {
  border-radius: 100px;
}

.vh100 {
  min-height: 100vh;
}

.watermark-bottom-right {
  background-image: url(../img/bg_logo.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
}

.overflow-auto {
  overflow: auto;
}

.img-profile{
  object-fit: cover;
  overflow: hidden;
  height: 32px; 
  width : 32px;
}

.img-square{
  object-fit: cover;
  overflow: hidden;
  height: 30vh; 
  width : 30vw;
}

.img-square-little{
  object-fit: cover;
  overflow: hidden;
  height: 15vw; 
  width : 12vw;
}

.img-square-little-wrapper{
  height: 15vh; 
  width : 100%;
  position: relative;
  display: inline-block;
  overflow: hidden;

  img{ width: 100%; }
}

.img-hover-delete{
  background: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  display: none !important;
}

.img-square-little-wrapper:hover .img-hover-delete{ display: flex !important; }

.upload-placeholder{
  border-radius: 3px;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23DD9DEFFF' stroke-width='2' stroke-dasharray='4%2c 9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.editor-box{
  border: 1px solid $light-gray;
  font-size: 1em;
  color: $gray;
  min-height: 3.6em;
  padding: 1.43em 2em;
  outline: none;
  position: relative;
  transition: border-color 0.3s linear;

  &:focus{
    border: 2px solid $purple;
  }

  p{ margin: 0; } 
}

.pdf-title:before{
  background: $black-text;
  top: -10px;
  content: "";
  display: block;
  height: 5px;
  position: relative;
  width: 10%;
}

.pdf-content:before{
  background: $black-text;
  top: -25px;
  left: -2%;
  content: "";
  display: block;
  height: 5px;
  position: relative;
  width: 104%;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  background-color: #ededed;
}