/* BUTTONS */
.btn {
  border: 1px solid $dark-gray;
  border-radius: 0;
  padding: 10px 25px;

  &__icon-right {
    padding-left: 15px;
  }
}

.btn-clean {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  border: 0;
  background: none;
  text-decoration: underline;
}

.btn-purple {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  background-color: $purple;
  border: 0;
  border-radius: 6px;
  color: $white !important;
  padding: 10px 25px;
  display: inline-block;
  width: 100%;
  text-decoration: none !important;
  text-align: center;

  &.w-100{ padding: auto !important; }
  &.w-50{ 
    width: 50% !important;
    margin: 0 0.5em !important; 
    padding: 10px 25px !important;
    display: inline !important;
  }

  .muestras-icon,
  .recogidas-icon{
    fill: $purple-light;
  }
}

.btn-purple-text{
  color: $purple;
  text-decoration: underline;
  background-color: transparent !important;
  border: 0!important;
  padding: 0;

  &:hover{
    color: $purple-light;
  }
}

.btn-white {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  background-color: $white;
  border: 1px solid $purple;
  border-radius: 6px;
  color: $purple !important;
  padding: 10px 25px;
  display: inline-block;
  width: 100%;
  text-decoration: none !important;
  text-align: center;

  .muestras-icon,
  .recogidas-icon{
    fill: $purple-light;
  }
}

.btn-circle {
  border-radius: 50px;
  padding: .3rem .65rem;
  ;
}

.btn-plus,
.btn-minus {
  border: 1px solid $purple;
  background-color: $purple;
  color: $white;
  font-size: 1.6em;
  font-weight: bold;
  line-height: 2em;
  text-align: center;
  padding: 0;
}

.btn-full {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}

.btn-orange {
  background-color: $orange !important;
  color: $white !important;
}

.btn-submit {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  width: 10em;
  height: auto;
}

.btn-submit .circle {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.5em;
  height: 2.5em;
  background: $orange;
  border-radius: 1.625em;
}

.btn-submit .circle .icon {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.btn-submit .circle .icon.arrow {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.4em;
  width: 1.125em;
  height: 0.125em;
  background: none;
}

.btn-submit .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25em;
  right: 0.0625em;
  width: 0.625em;
  height: 0.625em;
  border-top: 0.125em solid #fff;
  border-right: 0.125em solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn-submit .button-text {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75em 0;
  margin: 0 0 0 1.85em;
  color: $orange;
  font-weight: 300;
  line-height: 1;
  text-align: center;
}

.btn-submit:hover .circle {
  width: 100%;
}

.btn-submit:hover .circle .icon.arrow {
  background: $white;
  -webkit-transform: translate(1em, 0);
  transform: translate(1em, 0);
}

.btn-submit:hover .button-text {
  color: $white;
}

.btn-add-item {
  line-height: 4em;
  display: inline-block;
  color: $purple;
  cursor: pointer !important;
  text-decoration: underline;

  &:hover {
    color: $purple;
    text-decoration: underline;
  }
}

.btn-submenu{
  font-size: 1.2em;
  color: $edit-gray;
  padding: 1em 1.3em;
  text-decoration: none;
  line-height: 1em;

  &:hover,
  &.active{
    background-color: $gray-bg-btn;
    border-radius: 5px;
    color: $black-text;
  }
}

.btn-tool{
  border: 1px solid $light-gray;
  border-radius: 3px;
  background: $gray-bg;
  padding: 1em 1.2em;

  &:hover{
    background-color: $gray-bg-btn;
  }
}

.disabled{ 
  opacity: 0.6;
  cursor: default;
}

@media (min-width: 768px) {
  .btn-purple,
  .btn-white {
    width: auto !important;
    padding: 10px 55px;

    &.w-100{ 
      width: 100% !important;
      padding: 0 !important; 
    }
  }
}