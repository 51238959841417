/* Ant Library override */
.ant-select {
  border: 1px solid $light-gray;
  font-size: 1em;
  border-radius: 0;
  outline: none;
  box-shadow: none;
}

.ant-select-single.little:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 2em 0 1em;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
  height: auto;
  padding: 0 2em;
}

.ant-select-selector span {
  line-height: 4.45em !important;
}

.ant-select.little .ant-select-selector span {
  line-height: 2.8em !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  border-right: 1px solid $light-gray;
  right: 3.5em;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: transparent;
  box-shadow: none;
}

.ant-select-selection-search-input {
  font-size: 1.03em;
}

.ant-select-arrow {
  font-size: 1.2em;
  color: $purple;
  top: 1.9em;
  right: 1.1em;
}

.ant-select.little .ant-select-arrow {
  top: 1.1em;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 50px;
}

.ant-select-selection-placeholder {
  color: $gray-placeholder;
  opacity: 1;
}

.ant-select-item {
  line-height: 1.6em;
}

span.anticon {
  display: inline-block;
  vertical-align: middle;
}

.ant-popover {
  max-width: 400px;

  .ant-popover-inner-content {
    padding: 2.5em;
  }

  p {
    color: $gray;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.ant-checkbox-wrapper{ font-size: 1em !important; }

.ant-checkbox-inner {
  border: 1px solid $gray-border-checkbox;
  border-radius: 3px;
  background: #FFFFFF;
  width: 24px;
  height: 24px;

  &::after {
    width: 7px;
    height: 12px;
    left: 30%;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $purple;
  border-color: $purple;
}

.ant-checkbox-checked::after {
  border: 1px solid $purple;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: $purple;
}

.ant-checkbox + span { padding-left: 14px; }


.ant-radio{ top: -1px; }

.ant-radio-wrapper:hover .ant-radio, 
.ant-radio:hover .ant-radio-inner, 
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $purple;
}

.ant-radio-inner {
  width: 16px;
  height: 16px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $purple;
  background-color: $white;
}

.ant-radio-inner::after{
  width: 8px;
  height: 8px;
  background-color: $purple;
  top: 3px;
  left: 3px;
}

.ant-btn-primary,
.ant-btn-primary:focus {
  color: #fff;
  background-color: $purple;
  border-color: $purple;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

  &:hover {
    opacity: 0.8;
    color: #fff !important;
    background-color: $purple !important;
    border-color: $purple !important;
  }
}

.ant-btn {
  &:hover {
    opacity: 0.85;
    color: $purple;
    background-color: $white;
    border-color: $purple;
  }
}

// Ant table override
.ant-table {
  box-shadow: 0 4px 12px 0 #F5F3EF;
  font-family: 'Poppins', sans-serif !important;
  padding: 0 24px 32px 24px;
}

.ant-table-thead {
  &>tr>th {
    font-size: 12px;
    font-family: 'Poppins', sans-serif !important;
    background-color: #fff;
    padding: 20px 14px;
    color: $purple;

    &:first-child {
      padding-left: 56px;

      .ant-table-column-sorters{ padding-left: 0 !important }
    }

    &:last-child {
      padding: 0;
      margin: 0;
    }
  }

  &>tr>th span{ 
    font-size: 12px;
    font-family: 'Poppins', sans-serif !important; 
  }
}

.ant-table-tbody {
  &>tr>td {
    font-size: 12px;
    font-family: 'Poppins', sans-serif !important;
    padding: 14px;
    border-bottom: 1px solid #EBEBEB;

    &:first-child {
      padding-left: 56px;
    }

    &:last-child {
      padding: 0;
      margin: 0;
    }
  }
}

.ant-table-tbody>tr.ant-table-row:hover>td {
  background-color: #fff;
}


.ant-table-tbody>tr.ant-table-row:hover:after {
  content: "";
  display: inline-block;
  position: absolute;
  /* top: 50%; */
  left: 50%;
  width: calc(100% + 24px);
  height: 55px;
  background-color: #fff;
  transform: translate(-50%, 0px);
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.07);
  z-index: -1;
  transition: all .1s ease-in-out;
}

.ant-avatar-square{
  border-radius: 10px !important;
  background-color: $purple-light !important;
  color: $purple !important;
}

.ant-pagination-item-active,
.ant-pagination-item-active:hover{
  border-color: $purple;

  a,
  a:hover,
  a:active,
  a:focus{ color: $purple; }
}

.ant-pagination-item:hover,
.ant-pagination-next:hover,
.ant-pagination-prev:hover,
.ant-pagination-item:active,
.ant-pagination-next:active,
.ant-pagination-prev:active,
.ant-pagination-item:focus,
.ant-pagination-next:focus,
.ant-pagination-prev:focus
{
  border-color: $purple !important;

  a,
  a:hover,
  a:active,
  a:focus{ color: $purple !important; }
}

.ant-tooltip-inner{
  border-radius: 10px;
  background-color: #E7E7E7;
  font-size: 12px;
  color: $gray;
}

.ant-tooltip-arrow::before {
    width: 10px;
    height: 30px;
    background-color: #E7E7E7;
}

.ant-spin-blur{ opacity: 0.15; }

.ant-spin-container {
    -webkit-transition: opacity 0s;
    transition: opacity 0s;
}

.styles-module_carousel-arrow__26sRw{
  border-radius: 8px;
  border: 0;
  background: #FFFFFF;
  width: 32px;
  height: 32px;
  position: absolute;
  margin-top: 5em;
}

.styles-module_carousel-arrow__26sRw[data-direction="right"]{
  right: 4em;
}

.styles-module_carousel-arrow__26sRw[data-direction="left"]{
  left: 4em;
}

.styles-module_carousel-arrow__26sRw[data-direction="right"]:before{
  content: '>';
  color: $purple-light;
  font-size: 2em;
  line-height: 1.4em;
}

.styles-module_carousel-arrow__26sRw[data-direction="left"]:before{
  content: '<';
  color: $purple-light;
  font-size: 2em;
  line-height: 1.4em;
}